
@include media-breakpoint-down(sm) {
    .radio-card-icon{
        font-size: 25vw!important;
        max-width: 25vw!important;
        display: block!important;
        text-align: center;
        margin: auto;
        
    }     
}
.radio-card-row{
    border: 1px solid #ccc;
    padding: 0.5em 0;
    cursor: pointer;

    .radio-card-col{
        display: flex;

        .radio-card-input, .radio-card-icon, .radio-card-label{
            align-self: center;
        }

        .radio-card-icon{
            font-size: 2.5vw;
            line-height: 100%;
            max-width: 25vw;
            text-align: center;
            margin: auto;
            
        }

        .custom-control-label::after{
            cursor: pointer!important;
        }
    }
}