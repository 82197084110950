@import "../../assets/scss/theme";

$wizard-width: 20vw;

.wizard {
    position: fixed;
    width: $wizard-width;
    min-width: 300px;
    padding: 5% 2%;
    top: 0;
    bottom: 0;
    right: Min(-324px, (-1 * $wizard-width) - 2vw);
    z-index: 9999;
    background-color: white;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    transition: right 300ms ease-in-out;

    &.open{
        right: 0;
    }

    .wizard-title {
        margin: 2% 0;

        .wizard-toggle {
            background-color: white;
            position: absolute;
            top: 2%;
            z-index: 9;
            right: calc(100% - 18px);
            cursor: pointer;
            padding: 2%;
            border: 1px solid $primary;
            border-radius: 50%;
        }

        @media screen and (max-width: 792px) {
            margin-top: 10%;
        }
    }

    .wizard-content {
        overflow-y: scroll;
        max-height: 100%;
        @media screen and (max-width: 992px) {
            max-height: 90%;
        }
    }
}