@import '../../assets//scss/theme';

.experienceBar {
    display: flex;
    align-items: center;

    &>div {
        flex: 1;
        height: 6px;
        border-radius: 4px;
        background: $gray-500;
        margin: 0 1.5rem;
        position: relative;
        &>div {
            height: 6px;
            border-radius: 4px;
            background: $success;
        }
        
    }
    span {
        font-size: 1rem;
        &.currentExperience {
            position: absolute;
            top: 12px;
            transform: translateX(-50%);
            white-space: nowrap;
        }
    }
}